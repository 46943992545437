
import { serviceProvider } from './serviceProvider'

export const getUserDetailsService = (token,data) => serviceProvider.get('/m5/getUserDetails/'+token,{headers: { Authorization: `Bearer ${token}` }});


export const getPortfolios = () => serviceProvider('/');
export const login = (data) => serviceProvider.post('/medAuth/login', data);
export const logout = (token,data) => serviceProvider.delete('/medAuth/logout/'+token, data);

export const updateStripe = (data) => serviceProvider.post('/admin/updateStripe/'+data.token,data);

export const setSocialToken = (data) => serviceProvider.post('/auth/setSocialToken/'+data.token,data);
export const logoutFromSocial = (data) => serviceProvider.post('/auth/logoutFromSocial/'+data.token,data);

//Rumu
export const createStripeLoginLink = (data) => serviceProvider.get('/m5/createStripeLoginLink',{headers: { Authorization: `Bearer ${data.token}` }});

export const makePayment = (data) => serviceProvider.post('/m5/makePayment',{...data,token:undefined},{});

export const createClinic = (data) => serviceProvider.post('/m5/createClinic',{...data,token:undefined},{headers: { Authorization: `Bearer ${data.token}` }});
export const updateClinic = (data) => serviceProvider.post('/m5/updateClinic',{...data,token:undefined},{headers: { Authorization: `Bearer ${data.token}` }});
export const getClinics = (data) => serviceProvider.get('/m5/getClinics',{headers: { Authorization: `Bearer ${data.token}` }});

export const getRequests = (data) => serviceProvider.get('/m5/getRequests',{headers: { Authorization: `Bearer ${data.token}` }});

export const createPatient = (data) => serviceProvider.post('/m5/createPatient',{...data,token:undefined},{headers: { Authorization: `Bearer ${data.token}` }});
export const updatePatient = (data) => serviceProvider.post('/m5/updatePatient',{...data,token:undefined},{headers: { Authorization: `Bearer ${data.token}` }});
export const getPatients = (data) => serviceProvider.get('/m5/getPatients',{headers: { Authorization: `Bearer ${data.token}` }});
export const getPatientsBySearch = (data) => serviceProvider.get('/m5/getPatientsBySearch/'+data.search,{headers: { Authorization: `Bearer ${data.token}` }});

export const createBooking = (data) => serviceProvider.post('/m5/createBooking',{...data,token:undefined},{headers: { Authorization: `Bearer ${data.token}` }});
export const updateBooking = (data) => serviceProvider.post('/m5/updateBooking',{...data,token:undefined},{headers: { Authorization: `Bearer ${data.token}` }});
export const getBookings = (data) => serviceProvider.get('/m5/getBookings',{headers: { Authorization: `Bearer ${data.token}` }});
export const loadBooking = (data) => serviceProvider.get('/m5/loadBooking/'+data.code,{});


export const createTimeslot = (data) => serviceProvider.post('/m5/createTimeslots',{...data,token:undefined},{headers: { Authorization: `Bearer ${data.token}` }});
export const updateTimeslot = (data) => serviceProvider.post('/m5/updateTimeslot',{...data,token:undefined},{headers: { Authorization: `Bearer ${data.token}` }});
export const getTimeslots = (data) => serviceProvider.get('/m5/getTimeslots',{headers: { Authorization: `Bearer ${data.token}` }});


//Medlaw Medic Specific
export const sendResetPasswordEmail = (data) => serviceProvider.post('/medAuth/sendResetPasswordEmail',{...data,token:undefined},{headers: { Authorization: `Bearer ${data.token}` }});
export const updateClinics = (data) => serviceProvider.post('/medAuth/updateClinics',{...data,token:undefined},{headers: { Authorization: `Bearer ${data.token}` }});
export const updateMedic = (data,token) => serviceProvider.post('/m5/updateMedic',{...data,token:undefined},{headers: { Authorization: `Bearer ${token}` }});
export const resetPassword = (data) => serviceProvider.post('/medAuth/resetPassword',{...data,token:undefined},{headers: { Authorization: `Bearer ${data.token}` }});
export const getMedicRecords = (data) => serviceProvider.get('/medAuth/getMedicRecords',{headers: { Authorization: `Bearer ${data.token}` }});

//MedLaw Specific
export const sendConfirmationEmails = (data) => serviceProvider.post('/medAuth/sendConfirmationEmails',{...data,token:undefined},{headers: { Authorization: `Bearer ${data.token}` }});
export const sendReminderEmails = (data) => serviceProvider.post('/medAuth/sendReminderEmails',{...data,token:undefined},{headers: { Authorization: `Bearer ${data.token}` }});
export const createDateTime = (data) => serviceProvider.post('/medAuth/createDateTime',{...data,token:undefined},{headers: { Authorization: `Bearer ${data.token}` }});
export const deleteDateTime = (data) => serviceProvider.post('/medAuth/deleteDateTime',{...data,token:undefined},{headers: { Authorization: `Bearer ${data.token}` }});
export const getMedicTypes = (data) => serviceProvider.get('/medAuth/getMedicTypes',{headers: { Authorization: `Bearer ${data.token}` }});

export const freeDate = (data) => serviceProvider.post('/admin/freeDate',{...data,token:undefined},{headers: { Authorization: `Bearer ${data.token}` }});

export const updateTimes = (data) => serviceProvider.post('/admin/updateTimes',{...data,token:undefined},{headers: { Authorization: `Bearer ${data.token}` }});


export const getAllRecords = (data) => serviceProvider.get('/admin/getAllRecords',{headers: { Authorization: `Bearer ${data.token}` }});
export const updateRecord = (data, token) => serviceProvider.put('/admin/updateRecord',{...data,token:undefined},{headers: { Authorization: `Bearer ${token}` }});

export const resendEmails = (data, token) => serviceProvider.post('/admin/resendEmails/'+data.formId,{...data,token:undefined},{headers: { Authorization: `Bearer ${token}` }});

export const createMedicContact = (data, token) => serviceProvider.post('/admin/createMedic',{...data,token:undefined},{headers: { Authorization: `Bearer ${token}` }});
export const getAllMedics = (data) => serviceProvider.get('/admin/getAllMedics',{headers: { Authorization: `Bearer ${data.token}` }});

export const createSolicitorContact = (data, token) => serviceProvider.post('/admin/createSolicitor',{...data,token:undefined},{headers: { Authorization: `Bearer ${token}` }});
export const getAllSolicitors = (data) => serviceProvider.get('/admin/getAllSolicitors',{headers: { Authorization: `Bearer ${data.token}` }});
export const updateSolicitor = (data, token) => serviceProvider.put('/admin/updateSolicitor',{...data,token:undefined},{headers: { Authorization: `Bearer ${token}` }});

export const getSolicitorsBySearch = (data) => serviceProvider.get('/admin/getSolicitorsBySearch/'+data.search,{headers: { Authorization: `Bearer ${data.token}` }});
export const getMedicsBySearch = (data) => serviceProvider.get('/admin/getMedicsBySearch/'+data.search,{headers: { Authorization: `Bearer ${data.token}` }});

//Company
export const getCompany = (data) => serviceProvider.get('/admin/getCompany/'+data.token,data);
export const updateCompany = (data) => serviceProvider.put('/admin/updateCompany/'+data.token,data);
export const uploadCompanyLogo = (data,token) => {
  return serviceProvider.post('/admin/uploadCompanyLogo',{...data,token:undefined},{
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${token}`
        }
    });
}
