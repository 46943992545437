import { useCallback, useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import {TextInput} from '../../components/TextInput/index.js';
import Cookies from 'universal-cookie';
import { ClipLoader } from "react-spinners";
import {SideMenu} from '../../components/SideMenu/';
import {getClinics, getTimeslots, getPatientsBySearch, getRequests, createBooking, updateBooking, getMedicRecords,  getUserDetailsService, createStripeLoginLink} from '../../services/';
import styles from './styles.module.scss';
import getPaymentStatus from '../../utils/getPaymentStatus';
import filterBookings from '../../utils/filterBookings';

import plusIcon from '../../images/plus-icon.png';
import xButton from '../../images/x-button.png';
import profileURL from "../../images/default-profile.png";
import {MONTHS, SHORT_MONTHS, TIMES} from '../../config/times.config';
import {PAYMENT_STATUS_FILTERS} from '../../config/filters.config';

const STATUS_LIST = [
  'Pending',
  'Confirmed',
  'Cancelled'
]

const DOMAIN = "https://portal.rumu-health.com"

export const Requests = () => {
  const [user, setUser] = useState({});
  const [search,setSearch] = useState('');
  const [results, setResults] = useState([]);
  const [loading,setLoading] = useState(true);
  const [token,setToken] = useState(null);
  const [newBookingOpen,setNewBookingOpen] = useState(false);

  const [loadingForm,setLoadingForm] = useState(false);
  const navigate = useNavigate();

  const newDateRef = useRef(null);
  const patientNameRef = useRef(null);
  const patientEmailRef = useRef(null);
  const patientPhoneRef = useRef(null);

  const [newDate, setNewDate] = useState(new Date());
  const [bookings, setBookings] = useState([]);

  const [paymentStatusFilter, setPaymentStatusFilter] = useState(PAYMENT_STATUS_FILTERS[0])
  const [filterStartDate, setFilterStartDate] = useState(null)
  const [filterEndDate, setFilterEndDate] = useState(null)

  const [newPatientName, setNewPatientName] = useState('')
  const [newPatientEmail, setNewPatientEmail] = useState('')
  const [newPatientPhone, setNewPatientPhone] = useState('')
  
  const [editBookingName, setEditBookingName] = useState('')
  const [editBookingAddress, setEditBookingAddress] = useState('')
  const [editBookingDob, setEditBookingDob] = useState('')
  const [editBookingEmail, setEditBookingEmail] = useState('')
  const [editBookingPhone, setEditBookingPhone] = useState('')
  const [editRequestId, setEditRequestId] = useState('')
  const [editBookingId, setEditBookingId] = useState('')

  const [newBookingPatientId, setNewBookingPatientId] = useState(null)
  const [patients, setPatients] = useState([])
  const [clinics, setClinics] = useState([])
  const [newBookingClinic, setNewBookingClinic] = useState([])
  const [newBookingTimeslot, setNewBookingTimeslot] = useState([])
  const [newBookingDocumentURLs, setNewBookingDocumentURLs] = useState([])

  const [newTimeslotClinic, setNewTimeslotClinic] = useState([])

  const [allTimeslots, setAllTimeslots] = useState([])
  const [newBookingDate, setNewBookingDate] = useState(new Date())
  const [newBookingFee, setNewBookingFee] = useState(new Date())

  const [patientSymptoms, setPatientSymptoms] = useState('')
  const [patientAddress, setPatientAddress] = useState('')

  const [sendEmail, setSendEmail] = useState(true)

  const [code, setCode] = useState(null)

  const [records, setRecords] = useState([]);

  const getReferralDate = (dateString, time)=>{
    let date = dateString.replaceAll(' ','').replaceAll('.','/').split('/')

    let d = date[0],
      m = parseInt(date[1]) - 1,
      y = date[2];

    let h = time.replaceAll(';',':').replaceAll('pm','').replaceAll('PM','').replaceAll('am','').replaceAll('AM','').split(':')[0],
        min = time.replaceAll(';',':').replaceAll('pm','').replaceAll('PM','').replaceAll('am','').replaceAll('AM','').split(':')[1];

      if(y && y.length == 2) y = '20'+y

    if(!d || !m || !y || !h || !min) return 'error'


    const timeInt = Date.parse(d+' '+SHORT_MONTHS[m]+' '+y+' '+h+':'+min+':00 GMT')

    return timeInt
  }

  const getDate = (date, noTime = false)=>{
    let newDate = new Date(date);
    return newDate.getDate()+" "+(MONTHS[newDate.getMonth()].short)+" "+newDate.getFullYear()
  }

  const getDateTime = (date, noTime = false)=>{
    let newDate = new Date(date);
    return newDate.getDate()+"/"+(newDate.getMonth()+1)+"/"+newDate.getFullYear()+" "+newDate.getHours()+":"+newDate.getMinutes()
  }


  const init = async()=>{
    const cookies = new Cookies();
    const token = await cookies.get('token');
    if(!token){
      navigate('/');
    }else{
      setToken(token);
      const loadedBookingsData = await getRequests({token})
      if(loadedBookingsData.data.success){
        setBookings(loadedBookingsData.data.requests.reverse())
      }else{
        alert("Error getting your requests.");
        console.log(loadedBookingsData.data)
      }

      const loadedTimeslotsData = await getTimeslots({token})
      if(loadedTimeslotsData.data.success){
        setAllTimeslots(loadedTimeslotsData.data.timeslots);
      }else{
        alert("Error getting your timeslots.");
      }

      const loadedClinicsData = await getClinics({token})
      if(loadedClinicsData.data.success){
        setClinics(loadedClinicsData.data.clinics);
      }else{
        alert("Error getting your clinics.");
      }

      const userDetails = await getUserDetailsService(token,{});
      if(userDetails.data.success){
        setUser(userDetails.data);
      }else{
        console.log(userDetails);
        alert("Error getting your details.");
      }

      const recordDetails = await getMedicRecords({token});
      if(recordDetails.data.success){
        setRecords(recordDetails.data.records.sort((a,b)=>{

          if(getReferralDate(a.appointmentDate, a.appointmentTime) == 'error' && getReferralDate(b.appointmentDate, b.appointmentTime) != 'error') return 1;
          if(getReferralDate(a.appointmentDate, a.appointmentTime) != 'error' && getReferralDate(b.appointmentDate, b.appointmentTime) == 'error') return -1;
          if(getReferralDate(a.appointmentDate, a.appointmentTime) == 'error' && getReferralDate(b.appointmentDate, b.appointmentTime) == 'error') return 0;


          if(getReferralDate(a.appointmentDate, a.appointmentTime) > getReferralDate(b.appointmentDate, b.appointmentTime)){
            return -1
          }else if(getReferralDate(a.appointmentDate, a.appointmentTime) < getReferralDate(b.appointmentDate, b.appointmentTime)){
            return 1
          }

          return 0
        }));
      }else{
        alert("Error getting your records.");
      }

      setLoading(false);
    }

  }


  useEffect(()=>{
    init();
  },[]);


  if(loading){
    return(
      <div className="global-loading-page">
        <div className="global-loader">
          <ClipLoader  size={30} color={"#147d61"} />
        </div>
      </div>
    )
  }

  const renderPaymentCode = ()=>{
    if(!code) return;

    //Form Should Contain: Timeslot (includes clinic), searched patient, price
    return(
      <div className={styles.ordersNewProductWindowContainer}>
        <div className={styles.ordersNewProductWindow}>
          <img className={styles.ordersXButton} src={xButton} onClick={(event)=>{setCode(null)}} />
          <div className={styles.ordersRecentBookingsTitleContainer}>
            <div className={styles.ordersNewProductHeader}>
              <img className={styles.ordersRecentBookingsTitleIcon} src={require('../../images/logo-main.png')}/>
              <div className={styles.ordersRecentBookingsTitleText}>Booking Payment Link</div>
            </div>
          </div>
          <div className={styles.bookingCompleteForm}>
              Your Booking Was Created. <br/><br/>
              The payment link for your client is: <br/><br/><br/>
              {DOMAIN}/pay?c={code}
          </div>
        </div>
      </div>
    )
  }


  const renderNewBooking = ()=>{
    if(!newBookingOpen) return;

    //Form Should Contain: Timeslot (includes clinic), searched patient, price
    return(
      <div className={styles.ordersNewProductWindowContainer}>
        <div className={styles.ordersNewProductWindow}>
          <img className={styles.ordersXButton} src={xButton} onClick={(event)=>{setNewBookingOpen(false)}} />
          <div className={styles.ordersRecentBookingsTitleContainer}>
            <div className={styles.ordersNewProductHeader}>
              <img className={styles.ordersRecentBookingsTitleIcon} src={require('../../images/logo-main.png')}/>
              <div className={styles.ordersRecentBookingsTitleText}>New Booking</div>
            </div>
          </div>
          <div className={styles.ordersForm}>

          <div style={{width:"100%",display:"flex",flexDirection:"column"}}>
            <div className={"global-dashboard-row"}>
              <div style={{marginLeft:"10px", marginRight:"10px"}} className={"global-dashboard-row-card-white"}>

                <div className={styles.cardHeader}>New Booking Details</div>
                <div className={styles.cardColumnContainer}>
                  <div className={styles.cardColumn}>
                  <TextInput placeholder="Patient"  type="form-search" onChangeText={async(value)=>{
                    const cookies = new Cookies();
                    const token = cookies.get('token');

                    let res = await getPatientsBySearch({token, search:value})

                    if(res.data.success){
                      setPatients(res.data.patients)
                    }
                  }} options={patients} onChange={(patient)=>{
                    setNewBookingPatientId(patient.id)
                    setNewPatientEmail(null)
                    setNewPatientName(null)
                    setNewPatientPhone(null)
                    patientNameRef.current.setTextboxValue('')
                    patientEmailRef.current.setTextboxValue('')
                    patientPhoneRef.current.setTextboxValue('')
                    }} initialValue={''}/>
                  <br/><br/>
                  or create a patient
                  <br/><br/>
                  <TextInput placeholder="Patient Name" ref={patientNameRef} noHeader={true} type="form-text" onChange={(value)=>{setNewPatientName(value)}} initialValue={newPatientName}/>
                  <TextInput placeholder="Patient Phone" ref={patientPhoneRef} noHeader={true} type="form-text" onChange={(value)=>{setNewPatientPhone(value)}} initialValue={newPatientPhone}/>
                  <TextInput placeholder="Patient Email" ref={patientEmailRef} noHeader={true} type="form-text" onChange={(value)=>{setNewPatientEmail(value)}} initialValue={newPatientEmail}/>
                  <br/><br/>
                  Patient Symptoms:<br/>
                  {patientSymptoms}
                  <br/><br/>
                  Patient Address:<br/>
                  {patientAddress}
                  <br/><br/>
                  Documents:<br/>
                  {
                    newBookingDocumentURLs.map((document,documentIndex)=>{
                      return(<div className={styles.documentLink}><a href={document}>Uploaded File {documentIndex+1}</a></div>)
                    })
                  }
                  <br/><br/>
                  <TextInput placeholder="Total Fee (€)" noHeader={true} type="form-text" onChange={(value)=>{setNewBookingFee(value)}} initialValue={''}/>

                  </div>
                  <div className={styles.cardColumn}>
                    <TextInput placeholder="Clinic"  type="clinics-dropdown" options={clinics} onChange={(clinic)=>{
                      setNewBookingClinic(clinic)
                    }} initialValue={''}/><br/><br/>
                    <TextInput placeholder="Date Filter" type={'search-date'} onChange={(e)=>{

                      let date = new Date(e)
                      setNewBookingDate(date)

                    }} initialValue={new Date()} />
                    <TextInput placeholder="Timeslots"  type="timeslots-dropdown" options={allTimeslots.filter((t)=>{ return (t.clinicId == newBookingClinic) && (getDate(t.startTime) == getDate(newBookingDate)) })} onChange={(timeslot)=>{setNewBookingTimeslot(timeslot)}} initialValue={''}/>
                    <br/><br/>
                    Or create new timeslot:
                    <TextInput placeholder="Clinic"  type="clinics-dropdown" options={clinics} onChange={(clinic)=>{
                      setNewTimeslotClinic(clinic)
                    }} initialValue={''}/><br/><br/>
                    <TextInput placeholder="Date*" id="abc" type="date" ref={newDateRef} onChange={(value)=>{setNewDate(value)}} initialValue={newDate}/>
                  </div>
                </div>
                <br/><br/>
                      <span style={{paddingRight:"0px"}}><input type="checkbox" id="checkbox1" name="checkbox1" checked={sendEmail} onChange={(event)=>{setSendEmail(!sendEmail)}}></input> Send Payment Email To Patient</span>
                <br/><br/>

                <TextInput large={true} type="submit-input" icon={plusIcon} placeholder="Save Booking" onClick={async(event)=>{
                  if(sendEmail && !(newBookingPatientId || newPatientEmail?.length)){
                    alert('Please enter an email to send the payment link to the client.')
                    return
                  }

                  await setLoading(true)
                  const cookies = new Cookies();
                  const token = await cookies.get('token');
                  await setLoadingForm(true)
                  const createRes = await createBooking({
                    timeslot:newBookingTimeslot,
                    patient:newBookingPatientId,
                    fee:newBookingFee,
                    clinicId:newTimeslotClinic,
                    startTime:new Date(newDate).getTime(),
                    newPatientName,
                    newPatientEmail,
                    newPatientPhone,
                    requestId:editRequestId,
                    token,
                    sendEmail
                  });
                  if(createRes.data.success){
                    setBookings(createRes.data.requests)
                    setNewBookingOpen(false)
                    setCode(createRes.data.code)
                  }else{
                    console.log(createRes.data)
                    alert('Error creating booking.');
                  }

                  await setLoading(false)
                }} />

                <br/>
              </div>
            </div>

            <div className={"global-dashboard-row"}>

            </div>
          </div>

            <div className={styles.formButtonContainer}>
            </div>
          </div>
        </div>
      </div>
    )
  }

  const renderEditBooking = ()=>{
    if(!editBookingId) return;

    return(
      <div className={styles.ordersNewProductWindowContainer}>
        <div className={styles.ordersNewProductWindow}>
          <img className={styles.ordersXButton} src={xButton} onClick={(event)=>{setEditBookingId(false)}} />
          <div className={styles.ordersRecentBookingsTitleContainer}>
            <div className={styles.ordersNewProductHeader}>
              <img className={styles.ordersRecentBookingsTitleIcon} src={require('../../images/logo-main.png')}/>
              <div className={styles.ordersRecentBookingsTitleText}>Edit Booking</div>
            </div>
          </div>
          <div className={styles.ordersForm}>

          <div style={{width:"100%",display:"flex",flexDirection:"column"}}>
            <div className={"global-dashboard-row"}>
              <div style={{marginLeft:"10px", marginRight:"10px"}} className={"global-dashboard-row-card-white"}>

                <div className={styles.cardHeader}>Booking Details</div>
                <div className={styles.cardColumnContainer}>
                  <div className={styles.cardColumn}>
                  <TextInput placeholder="Name" noHeader={true} type="form-text" onChange={(value)=>{setEditBookingName(value)}} initialValue={editBookingName}/>
                  <TextInput placeholder="Email" noHeader={true} type="form-text" onChange={(value)=>{setEditBookingEmail(value)}} initialValue={editBookingName}/>
                  <TextInput placeholder="Phone" noHeader={true} type="form-text" onChange={(value)=>{setEditBookingPhone(value)}} initialValue={editBookingPhone}/>

                  </div>
                  <div className={styles.cardColumn}>
                    <TextInput placeholder="Address" noHeader={true} type="form-text" onChange={(value)=>{setEditBookingAddress(value)}} initialValue={editBookingAddress}/>
                    <TextInput placeholder="Date Of Birth" noHeader={true} type="form-text" onChange={(value)=>{setEditBookingDob(value)}} initialValue={editBookingDob}/>

                  </div>
                </div>
                <br/>

                <TextInput large={true} type="submit-input" icon={plusIcon} placeholder="Save Booking" onClick={async(event)=>{
                  await setLoading(true)
                  const cookies = new Cookies();
                  const token = await cookies.get('token');
                  await setLoadingForm(true)
                  const updateRes = await updateBooking({dob:editBookingDob,phone:editBookingPhone,email:editBookingEmail,address:editBookingAddress,name:editBookingName,bookingId:editBookingId,token});
                  if(updateRes.data.success){
                    setBookings(updateRes.data.bookings)
                    await setEditBookingId(false)
                    await setEditBookingName('')
                    await setEditBookingAddress('')
                    await setEditBookingPhone('')
                    await setEditBookingEmail('')
                    await setEditBookingDob('')
                  }else{
                    console.log(updateRes.data)
                    alert('Error creating booking.');
                  }

                  await setLoading(false)
                }} />

                <br/>
              </div>
            </div>

            <div className={"global-dashboard-row"}>

            </div>



          </div>

            <div className={styles.formButtonContainer}>
            </div>
          </div>
        </div>
      </div>
    )
  }

  if(token){
    return(
      <div className="global-container">
        {renderNewBooking()}
        {renderEditBooking()}
        <div className={styles.homeContentContainerMain}>

          <SideMenu open={true} activeScreen={"Requests"} name={user.profileName} />
          <div className={styles.homeDashboardContentMain}>

            <div className="global-header">
              <div className="global-header-profile">
                <img src={user.profileURL || profileURL} className="global-header-profile-image" />
                <div className="global-header-profile-text">{user.name}</div>
              </div>


              <div className="global-header-buttons">
              </div>
            </div>

            <div style={{width:"100%",display:"flex",flexDirection:"column"}}>
              <div className={"global-dashboard-row"}>
                <div style={{marginLeft:"50px", marginRight:"50px", height:"85vh", display:"flex",flexDirection:"column", overflowY:"auto"}} className={"global-dashboard-row-card-white"}>
                  <div className={styles.cardHeader}>
                  <div className={styles.cardHeaderTitle}>
                    <img src={require('../../images/booking-icon.png')} className={styles.cardHeaderIcon} />
                    My Requests
                  </div>
                  <div className={styles.newBookingContainer}>
                    
                  </div>
                  <div className={styles.searchContainer}>
                    
                    
                  </div>
                  </div>


                  <table style={{overflowY:"auto", flex:"1"}}>
                    <tr className={styles.ordersRecentBookingsTableHeader}>
                      <th className={styles.productsResultHeaderItem}>Patient Name</th>
                      <th className={styles.productsResultHeaderItem}>Patient Email</th>
                      <th className={styles.productsResultHeaderItem}>GP Name</th>
                      <th className={styles.productsResultHeaderItem}>Patient Phone</th>
                      <th className={styles.productsResultHeaderItem}>Edit</th>
                    </tr>
                    {
                      filterBookings(bookings || [],{paymentStatusFilter,filterStartDate,filterEndDate}).map((record, recordIndex)=>{

                        if(recordIndex % 2 != 0){
                          return(
                            <tr className={styles.productsRowEven} style={{backgroundColor:(record.status == 2)?'#ff667d':'#ffffff'}} onClick={async(event)=>{

                            }}>
                              <td className={styles.ordersRecentBookingsRowEvenItem}>{record.senderName}</td>
                              <td className={styles.ordersRecentBookingsRowEvenItem}>{record.senderEmail}</td>
                              <td className={styles.ordersRecentBookingsRowEvenItem}>{record.gpName}</td>
                              <td className={styles.ordersRecentBookingsRowEvenItem}>{record.senderPhone}</td>
                              <td className={styles.ordersRecentBookingsRowEvenItem}>
                                <TextInput large={true} type="submit-input" icon={plusIcon} placeholder="Make Booking" onClick={async(event)=>{
                                  console.log(record)
                                  setEditRequestId(record.id)
                                  setPatientAddress(record.clientAddress)
                                  setNewPatientName(record.senderName)
                                  setNewPatientEmail(record.senderEmail)
                                  setNewPatientPhone(record.senderPhone)
                                  setNewBookingDocumentURLs(JSON.parse(record.documentURLs || '[]'))
                                  setPatientSymptoms(record.clientSymptoms)
                                  setNewBookingOpen(true)
                                }} />
                              </td>
                            </tr>
                          )
                        }

                        return(
                          <tr className={styles.productsRowOdd} style={{backgroundColor:(record.status == 2)?'#ff667d':'#f5f6ff'}} onClick={async(event)=>{

                          }}>
                            <td className={styles.ordersRecentBookingsRowEvenItem}>{record.senderName}</td>
                            <td className={styles.ordersRecentBookingsRowEvenItem}>{record.senderEmail}</td>
                            <td className={styles.ordersRecentBookingsRowEvenItem}>{record.gpName}</td>
                            <td className={styles.ordersRecentBookingsRowEvenItem}>{record.senderPhone}</td>
                            <td className={styles.ordersRecentBookingsRowEvenItem}>
                              <TextInput large={true} type="submit-input" icon={plusIcon} placeholder="Make Booking" onClick={async(event)=>{
                                setPatientSymptoms(record.clientSymptoms)
                                setPatientAddress(record.clientAddress)
                                setEditRequestId(record.id)
                                setNewPatientName(record.senderName)
                                setNewPatientEmail(record.senderEmail)
                                setNewPatientPhone(record.senderPhone)
                                setNewBookingDocumentURLs(JSON.parse(record.documentURLs || '[]'))
                                setNewBookingOpen(true)
                              }} />
                            </td>
                          </tr>
                        )
                      })
                    }
                  </table>
                </div>
              </div>
            </div>

          </div>
        </div>
        {renderNewBooking()}
        {renderPaymentCode()}
      </div>
    )
}


}
